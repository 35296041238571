<template>
  <div class="pay-total-box">
    <h2 class="pay-total-title g-ff-5">
      {{ title }}<span v-if="rightTitle">{{ rightTitle }}</span>
    </h2>
    <h6 class="title-tips" v-if="rightDescribe">{{ rightDescribe }}</h6>
    <div class="pay-total-value">
      <img src="@/static/svg/date&time/Date.svg" alt="" />
      <span v-if="date">{{ date | formatTimeDmy }}</span>
      <span class="date-disabled" v-else>DD/MM/YYYY</span>
      <span v-if="time && !time.includes('undefined')" class="pay-total-time">{{
        time
      }}</span>
      <span v-else class="pay-total-time disabled">00:00 - 00:00</span>
    </div>

    <div class="pay-total-money">
      <div
        class="pay-total-money-item"
        v-for="(money, idx) in moneyList"
        v-bind:key="idx"
      >
        <div class="pay-total-money-item-label">${{ money.label }}</div>
        <div class="pay-total-money-item-value">
          {{ moneyValue(money.value) }}
        </div>
      </div>
    </div>
    <div class="pay-total-total">
      Total
      <div><span class="pay-total-unit">AUD</span>{{ moneyValue(total) }}</div>
    </div>
    <div
      :class="[
        showPanel ? 'pay-total-rates open g-scroll-style' : 'pay-total-rates',
      ]"
    >
      <div class="pay-total-rates-title" @click="openPanel">
        {{ guideLineTitle }}
        <img v-if="showPanel" src="@/static/icon/View Less.png" alt="" /><img
          v-else
          src="@/static/icon/View More.png"
          alt=""
        />
      </div>
      <div class="ql-container ql-snow">
        <div class="ql-editor" v-html="htmlGuideLine"></div>
      </div>
      <div class="pat-pdf-down">
        <a
          href="javascript:void(0);"
          v-for="(item, index) in pdfGuideLine"
          :key="index"
          @click="fileLinkToStreamDownload(item.url)"
          ><img src="@/static/icon/tools/Download.png" alt="" />{{
            item.name
          }}</a
        >
      </div>
      <div class="pay-total-rates-line"></div>
      <!-- <div class="pay-total-rates-footer">
        OC Email: leon@leonsproperty.com.au
      </div> -->
    </div>
  </div>
</template>

<script>
/**
 * pay-total 支付总账
 * @description ---
 * @property : {String}  optionType 操作类型
 * @property : {String}  date 日期
 * @property : {String}  time 时间
 * @property : {Array}   moneyList 金额明细
 * @property : {String}  total 总金额
 * @property : {String}  content 内容
 * @property : {String}  apartmentId 公寓ID
 * @property : {String}  apartmentUnitId 单元ID
 */
import { formatMoney } from "@/utils/tools";
export default {
  name: "pay-total",
  props: {
    optionType: { type: [Number, String], default: undefined }, //1.booking ,2.change ,3.cancel(暂无)
    date: { type: String },
    time: { type: String },
    moneyList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total: { type: [String, Number], default: 0 },
    apartmentId: { type: [String, Number], default: undefined },
    apartmentUnitId: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      title: undefined,
      guideLineTitle: undefined,
      pdfGuideLine: [],
      showPanel: true,
      htmlGuideLine: undefined,
    };
  },
  watch: {
    apartmentUnitId() {
      this.init();
    },
  },
  created() {
    this.setTitle();
    this.init();
  },
  computed: {
    rightTitle() {
      return parseFloat(this.total) > 0 ? "" : "$0";
    },
    rightDescribe() {
      // if (this.optionType === 1) {
      //   return parseFloat(this.total) > 0 ? "" : "First Time";
      // }
      return "";
    },
  },
  methods: {
    setTitle() {
      if (this.optionType === 1) {
        this.title = "Moving Supervision Fee";
        this.guideLineTitle = "Moving Rules";
      } else if (this.optionType === 2) {
        this.title = "Reschedule Fee";
        this.guideLineTitle = "Reschedule and Cancellation policy";
      }
    },
    fileLinkToStreamDownload(url) {
      const fileName = "pdf";
      const xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.setRequestHeader("Content-Type", `application/pdf`);
      xhr.responseType = "blob";
      const that = this;
      xhr.onload = function () {
        if (this.status === 200) {
          // 接受二进制文件流
          const blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(blob); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = tagFileName; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放blob对象
    },
    openPanel() {
      this.showPanel = !this.showPanel;
    },
    init() {
      if (this.apartmentUnitId) {
        this.getGuideline();
      }
    },
    //获取规则信息
    getGuideline() {
      //type 	0：搬家指南 1：修改规则 2：取消规则
      let params = {
        apartmentUnitId: this.apartmentUnitId,
        type: +this.optionType - 1,
      };
      this.$axios
        .get(this.$api.getMoving(this.apartmentId), { params })
        .then((res) => {
          if (res.code === "1000" && res.data.apartmentGuideline) {
            this.htmlGuideLine = res.data.apartmentGuideline.content;
            this.pdfGuideLine = JSON.parse(res.data.apartmentGuideline.pdf);
          }
        });
    },
    moneyValue(value) {
      return formatMoney(value);
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
